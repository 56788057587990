.searchResult {
    cursor: pointer;
    border: solid 1px lightgrey;
    border-radius: 5px;
    background-color: rgba(255,255,255,1);
    margin-bottom: 10px;
    height: auto;
    padding: 0.5em 0.5em 0;
}

.searchResult:hover {
    background-color: rgba(36, 173, 211, 0.3);
    border: solid 1px rgba(36, 173, 211, 0.5);
}

.activeCode {
    background-color: rgba(36, 173, 211, 0.15);
    border: solid 1px rgba(36, 173, 211, 0.3);
    box-shadow: 2px 1px 4px -1px rgba(89,89,89,0.4);
}

/*Style for highlighted search results.*/
em {
    background-color:#FFFF00;
    font-style: normal;
}
