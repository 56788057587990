.external-link-button {
    color: #337ab7;
    border: #dee2e6 1px solid;
    margin-bottom:10px;
}

#attributesTable {
    border-radius: 0.25rem;
    border-spacing: 0px;
    border: #dee2e6 1px solid;
    border-collapse: separate;
}

#attributesTable thead {
    border-color: black;
}


