.modal-footer {
    justify-content: normal!important;
    display: inline-block !important;
}

.PopUpBtn {
    background-color: white;
    color: rgb(0, 0, 0);
    margin-left: 5px !important;
    border-radius: 5px;
    border: 1.5px solid rgba(24, 24, 24, 0.195);
}
