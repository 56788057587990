.beigeBackground {
  background-color: #DEDEDE;
}

#logo {
  display: block;
  max-width:200px;
  margin: 20px auto;
  cursor: pointer;
}
#title > img {
  max-width: 200px;
}

#calendarLogo {
  max-width: 20px;
  column-gap: 10px;
}

#searchResults {
  padding-left: 0;
  padding-right: 0;
}

#codeBody {
  margin-top: 15px;
}

#arrow-rotate {
  transform: rotate(180deg);
}

.whiteBackground {
  background-color: white;
}

#color {
  border-color: #24add3!important;
}
.text-start {
  padding: 10px;
}

.breadLink a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.breadLink a:hover {
  color: #23527c;
  text-decoration: underline;
}

.catalogAndSearchbarContainer {
  display: flex;
}

.searchbarItem {
  flex-grow: 1;
}

#collapse-button, #load-more-button, #reset-button {
  outline: 1px solid #ccc; /* Add an outline */
  background-color: rgba(255, 255, 255, 1) !important;
}

#collapse-button:hover, #load-more-button:hover, #reset-button:hover {
  background-color: rgb(234, 231, 231) !important;
  color: black !important;
}
