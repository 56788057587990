.header {
    width: 100%;
    top: 1%;
    right: 1%;
    padding: 10px;
}

.language-selection{
    text-align: right;
}

.language-btn{
    background-color: transparent;
    border-color: transparent;
    position: relative;
    color: #808080;
}
.language-btn:hover {
    text-decoration-line: underline;
}
#activeLan {
    color: #24add3;
    text-decoration-line: underline;
}
