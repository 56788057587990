#catalog_button:hover, #version_button:hover {
    background-color: rgb(234, 231, 231);
    color: black;
}

#catalog_button:focus, #version_button:focus {
    outline-style: none;
    box-shadow: none;
}

.centerMobileButtons {
    text-align: center;
}

.dropdown-item:hover {
    text-decoration-line: none;
}

.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}
