.footer {
    width: 100%;
    bottom: 0;
    left: 1%;
    padding: 10px;
    text-align: center;
}

a:visited {
  color:#24add3;
  text-decoration: none;
}

a:hover {
    text-decoration: underline;
  }
