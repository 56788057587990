.search-mobile {
    max-width: 983px;
    margin: auto;
    text-align: center;
    padding: 5px;
}

#btn-go {
    background-color: #24add3;
    border: none;
}
