body {
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   */
  background-color: rgba(245,245,245,0.8)!important;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 14px!important;
}
.link {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  color: #23527c;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
